import {
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";

class BluePage extends React.Component {
  componentDidMount() {
    const locale = this.props.intl.locale;
    window.location.href = `/${locale}/privacy-policy/`
  }
  render() {
    return (<></>);
  }
}

export default injectIntl(BluePage);
